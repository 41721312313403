import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {submissionsReducer, sidebarReducer, themeReducer, rtlReducer, reportingReducer, venueReducer, dashboardReducer,venuesReducer,usersReducer } from '../../redux/reducers/index';

const reducer = combineReducers({
    theme: themeReducer,
    sidebar: sidebarReducer,
    rtl: rtlReducer,
    reporting: reportingReducer,
    venue: venueReducer,
    venues: venuesReducer,
    users: usersReducer,
    submissions: submissionsReducer
});


const store = (process.env.NODE_ENV ==='development') ? createStore(reducer, applyMiddleware(thunk)
    +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
) :createStore(reducer, applyMiddleware(thunk));

export default store;
