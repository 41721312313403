import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import LogOut from '../LogOut/index';
import Reporting from '../Reporting/index';
import ReportView from '../Reporting/components/ReportView';
import Venue from '../Venue/index';
import VenueEdit from '../VenueEdit/index';
import VenuePhotos from '../VenuePhotos/index';
import Dashboard from '../Dashboard/index';
import Users from '../Users/index';
import Venues from '../Venues/index';
import Submissions from '../Submissions/index';
import ProtectedRoute from './ProtectedRoute';

// const Pages = () => (
//   <Switch>
//     <Route path="/pages/one" component={ExamplePageOne} />
//   </Switch>
// );

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
        {/*<Route path="/pages" component={Pages} />*/}
        <Route path="/reporting" component={Reporting} />
        <Route path="/reportView/:reportId" component={ReportView} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/dashboardV2" component={Dashboard} />
        <Route path="/users" component={Users} />
        <Route path="/submissions" component={Submissions} />
        <Route path="/venues" component={Venues} />
        <Route path="/venue/:venueId" component={Venue} />
        <Route path="/edit/:venueId" component={VenueEdit} />
        <Route path="/photos/:venueId" component={VenuePhotos} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/logout" component={LogOut} />
        <ProtectedRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
