import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Panel from "../../../../shared/components/Panel";
// import DataReactTable from "./DataReactTable";
import { Redirect, useHistory } from "react-router-dom";

const TransactionsTable = () => {


    return (
        <Panel
            xl={12}
            lg={12}
            md={12}
            title="Transactions"
            subhead="Transactions"
        >
            <div >



            </div>
        </Panel>
    );
};

export default TransactionsTable;
