import Http from '../containers/App/Http';
import store from '../containers/App/store';
import {setVenues} from "../redux/actions/venuesActions";

export default class Venues{

    async getVenues(){
        // FETCH THE VENUE INFO
        let formData = new FormData();
        formData.append("yo","mama");
        return Http.post("/vs/getVenues",formData)
            .then(async response => {
            if (response.data.status === 'failed') {
                // alert('failed');
                return false;
            }
            await store.dispatch(setVenues(response.data));
            return true;
        });
    };

    createVenueByGooglePlaceId(placeId){
        const formData = new FormData();
        formData.append('placeId',placeId);
        return Http.post("/vs/createNewVenueFromGooglePlaceID",formData)
            .then(async response => {
                return response;
            });
    }

    createBlankVenue(){

        return Http.post("/vs/createNewVenue")
            .then(async response => {
                return response;
            });
    }
}