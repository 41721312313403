import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {getAvailableReports, setSelectedReport} from '../../../redux/actions/reportingActions';
import {Button, ButtonToolbar, Card, CardBody, Col, Modal} from 'reactstrap';
import DataReactTable from './DataReactTable';
import { Redirect, useHistory } from "react-router-dom";
import Http from '../../App/Http';


const ReportSelector = ({ dispatch, availableReports, selectedReport}) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [table,setTable] = useState(false);

    const history = useHistory();

    const redirect = async (report) =>{
        // SET THE REPORT INTO STORE MEM BEFORE THE REDIRECT
        await dispatch(setSelectedReport(report));
        history.push(`/reportView/${report.r_id}`);
    };

    useEffect(async ()=>{

      return await Http.post("/r/getReports", null).then(response => {
          if (response.data.status === 'failed') {
              return false;
          }
          // COLUMNS
          const columns = [
              {
                  Header: 'Name',
                  accessor: 'r_name'
              },{
                  width: 300,
                  Header: "Open",
                  Cell: ({ cell }) => {
                      // debugger
                      return (
                          <button type={"button"} className={ "btn btn-primary btn-sm m-0 "} onClick={() => {redirect(cell.row.original)}}>
                              Open
                          </button>
                      )
                  }
              }
          ];
          // DATA

          const tableStructure = {
              tableHeaderData: columns,
              tableRowsData: response.data.data.reports
          };

          dispatch(getAvailableReports(tableStructure));
          setTable(tableStructure);
         setIsLoaded(true);
//           setTimeout(() => setIsLoaded(true), 1000);

      }).catch((err)=>{
          console.log(err);
      });
  },[getAvailableReports]);

    return (
      <Col md={12}>
          <Card>
              <CardBody>
                  <div id="SelectReportContainer">
                      <div className="row">
                          <div className="col-12">
                              {isLoaded ? <DataReactTable reactTableData={table} /> : ""}
                          </div>
                      </div>
                  </div>
              </CardBody>
          </Card>
      </Col>
    );
};

export default connect(state => ({
  availableReports: state.availableReports,
  selectedReport:state.selectedReport
}))(ReportSelector);
