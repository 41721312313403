import { handleActions } from 'redux-actions';
import {
    setAllUsers
} from '../actions/usersActions';

const defaultState = {

};

export default handleActions(
  {
    [setAllUsers](state, { payload }) {
        return {...state, users: payload};
    }
  },
  defaultState,
);
