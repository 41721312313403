import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import modelVenue from '../../../../Models/Venue';
import { Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';

const ModalChangeTier = ({dispatch, venueData, rtl}) => {
    const [modal, setModal] = useState(false);
    const [tier,setTier] = useState(null);
    const mv = new modelVenue();

    useEffect(async () => {
        setTier(venueData.vti_advanced_id);
    },[venueData]);

    const toggle = async () => {
        await setModal(prevState => !prevState);

        document.getElementById("mctTierId").value = tier;
    };

    const handleSubmit= (form) =>{
        form.preventDefault();

        let tier =document.getElementById("mctTierId").value;
        mv.changeVenueTier(venueData.v_id, tier).then(response => {
            if (response.data.status == 'failed') {
                alert('failed');
                return false;
            }
            window.location.reload();
        });
    };

    return (
        <div>
            <Button color={"info"} className={"btn-sm btn-block mb-0 mt-2"} onClick={toggle}>Change</Button>

            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="sm">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Change</h4>
                </div>
                <div className="modal__body">
                    <Form onSubmit={handleSubmit}>

                        <FormGroup className="mb-3">
                            <Label>Tier</Label>
                            <select id={"mctTierId"} className={"form-select form-control"}>
                                <option value={'FREE'}>Free</option>
                                <option value={'PREM'}>Premium</option>
                                <option value={'TRI1'}>Trial</option>
                            </select>
                        </FormGroup>

                        <ButtonToolbar className="modal__footer">
                            <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
                            <Button className="modal_ok" type={"submit"}>Submit</Button>
                        </ButtonToolbar>
                    </Form>
                </div>

            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    dateRangeEnding: state.venue.dateRangeEnding,
    dateRangeStarting:state.venue.dateRangeStarting,
    venueData:state.venue.venueData
}))(ModalChangeTier);