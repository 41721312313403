import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import modelVenue from '../../Models/Venue';
import { useParams } from 'react-router-dom';
import { getVenueData ,showVenueMenu} from '../../redux/actions/venueActions';
import classnames from 'classnames';
import {connect} from "react-redux";
import DataReactTable from "../Dashboard/components/DataReactTable";
import Panel from "../../shared/components/Panel";
import InviteMembers from "./components/InviteMembers";
import AddMembers from "./components/AddMembers";
import {showNotification} from "../../shared/components/Notification";
import MembersTable from "./components/MembersTable";
import VenueGoogleIntegration from "./components/VenueGoogleIntegration";
import Subscriptions from "./components/Subscriptions";
import Amenities from "./components/Amenities";
import StripeContainer from "../../shared/components/StripeContainer";

const VenueEdit = ({ dispatch, venueData,rtl,theme }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new modelVenue();
    const [activeTab, setActiveTab] = useState('1');


    // if(venueData.vt_ids == null || venueData.vt_ids.length === 0 || typeof venueData.vt_ids === "undefined")
    //     return;



    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        dispatch(showVenueMenu(true));

        if(!isLoaded){
            vm.getVenueData(venueId).then(async response => {

                if (response.data.status === 'failed') {
                    alert('failed');
                    return false;
                }


                await setIsLoaded(true);
                return true;
            });
        }

        if(isLoaded){
            Object.keys(venueData).forEach(key => {
                document.querySelectorAll('input[name='+key+']').forEach((el)=>{
                    el.value = venueData[key];
                });
                document.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                    el.value = venueData[key];
                });
            });
            document.querySelector('input[type=checkbox][name=v_outdoor_space_available]').checked = venueData.v_outdoor_space_available === 'Y';


            let venueTypeIds = venueData.vt_ids.split(",");
            // document.querySelectorAll('input[type=checkbox][name*=vt_ids][value='+id.trim()+']').forEach((x)=>console.log(x))
            venueTypeIds.forEach(function(id){

                // debugger
                document.querySelectorAll('input[type=checkbox][name*=vt_ids]').forEach((vtId)=>{

                    if(vtId.value === id.trim())
                        vtId.checked =true;
                });
                //.checked =true;
                // $('#venueEditForm [name*=vt_ids][value='+id.trim()+']').prop('checked', true);

                // document.querySelectorAll('input[type=checkbox]');
            });

        }



    },[venueId,isLoaded]);

    const updateVenue = (e) =>{
        e.preventDefault();
        let formData = new FormData(document.getElementById('venueEditForm'));
        // venue type ids
        let vt_ids = "";
        let comma = "";
        if(document.querySelectorAll('input[type=checkbox][name*=vt_ids]:checked').length > 0){
            document.querySelectorAll('input[type=checkbox][name*=vt_ids]:checked').forEach(function(x){
                vt_ids += comma + x.value;
                comma = ",";
            });
        }
        formData.append('vt_ids',vt_ids);

        let v_outdoor_space_available = "N";
        if(document.querySelectorAll('input[type=checkbox][name=v_outdoor_space_available]:checked').length > 0){
            v_outdoor_space_available = 'Y';
        }
        formData.append('v_outdoor_space_available',v_outdoor_space_available);

        vm.updateVenueData(venueId,formData).then((response)=>{
            if(response.data.status==='failed'){
                showNotification(theme,'danger',rtl,'Get Images',response.data.msg);
                return;
            }

            showNotification(theme,'success',rtl,'Update Venue',response.data.msg);
            return;
        });

    };



    return (

        isLoaded && (
            <Container className="dashboard">
                <Row>
                    <Panel xl={12} lg={12} md={12} title={venueData.v_name} refreshFunction="">
                        <div>
                            <div className="tabs tabs--bordered-top">
                                <div className="tabs__wrap">
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>Main</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')}>Members</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => toggle('3')}>Indexing</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => toggle('4')}>Subscriptions</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => toggle('5')}>Amenities</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <div className={"col-12 p-0"}>
                                                <form id="venueEditForm" className={"form--horizontal form"}>
                                                    {/*<div className="form__form-group row mt-3">*/}
                                                        {/*<label htmlFor="v_active" className="col-sm-1 form__form-group-label col-form-label">Is*/}
                                                            {/*Active</label>*/}
                                                        {/*<div className="col-sm-2">*/}
                                                            {/*<select className="form-control-sm" id="v_active" name="v_active" >*/}
                                                                {/*<option value="1">Yes</option>*/}
                                                                {/*<option value="0">No</option>*/}
                                                            {/*</select>*/}
                                                        {/*</div>*/}
                                                        {/*<label htmlFor="v_active" className="col-sm-1 form__form-group-label col-form-label">Tier</label>*/}

                                                    {/*</div>*/}
                                                    <div className="form__form-group row mt-3">
                                                        <label htmlFor="v_id" className="col-sm-1 form__form-group-label col-form-label">ID</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_id" name="v_id"   readOnly/>
                                                        </div>
                                                        <label htmlFor="v_sitting"
                                                               className="col-sm-1 form__form-group-label col-form-label">Sitting</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_sitting" name="v_sitting"  />
                                                        </div>
                                                        <label htmlFor="v_standing"
                                                               className="col-sm-1 form__form-group-label col-form-label">Standing</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_standing" name="v_standing"  />
                                                        </div>
                                                        <label htmlFor="v_max_capacity" className="col-sm-1 form__form-group-label col-form-label">Max Cap.</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_max_capacity" name="v_max_capacity" />
                                                        </div>
                                                    </div>
                                                    <div className="form__form-group row mt-3">
                                                        <label htmlFor="v_name" className="col-sm-1 form__form-group-label col-form-label">Name</label>
                                                        <div className="col-sm-11">
                                                            <input type="text" className="form-control-sm" id="v_name" name="v_name" />
                                                        </div>
                                                    </div>
                                                    <div className="form__form-group row mt-3">
                                                        <label htmlFor="v_website" className="col-sm-1 form__form-group-label col-form-label">Website</label>
                                                        <div className="col-sm-5">
                                                            <input type="text" className="form-control-sm" id="v_website" name="v_website" />
                                                        </div>
                                                        <label htmlFor="v_phone" className="col-sm-1 form__form-group-label col-form-label">Phone</label>
                                                        <div className="col-sm-5">
                                                            <input type="text" className="form-control-sm" id="v_phone" name="v_phone" />
                                                        </div>
                                                    </div>
                                                    <div className="row form__form-group">
                                                        <div className="form__form-group col-sm-6">
                                                            <label className={"form__form-group-label"} htmlFor="v_description">Description</label>
                                                            <textarea className="form-control-sm" id="v_description" name="v_description" rows="9"/>
                                                        </div>
                                                        <div className="form__form-group col-sm-6">
                                                            <label className={"form__form-group-label"} htmlFor="v_pricing">Pricing</label>
                                                            <textarea className="form-control-sm" id="v_pricing" name="v_pricing" rows="9"/>
                                                        </div>
                                                    </div>
                                                <hr/>
                                                <h5>Venue Types</h5>
                                                <div className="col-md-12" id="venue_types_checkboxes_wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="1" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper">
                                                            Hotels
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="2" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper">
                                                            Restaurant
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="3" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper">
                                                            Bar/Brewery
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="4" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper">
                                                            Country Club
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="5" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper"> Banquet/Party Hall</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="6" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper"> Others</label>
                                                    </div>
                                                </div>

                                                <hr/>
                                                <div className="col-md-12">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="Y" name="v_outdoor_space_available" id="v_outdoor_space_available"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="v_outdoor_space_available">Has Outdoor Space Available</label>
                                                    </div>
                                                </div>

                                                <div className="form__form-group row mt-3">
                                                    <label htmlFor="v_address" className="col-sm-1 form__form-group-label col-form-label">Address</label>
                                                    <div className="col-sm-3">
                                                        <input type="text" className="form-control-sm" id="v_address" name="v_address"/>
                                                    </div>
                                                    <label htmlFor="v_city" className="col-sm-1 form__form-group-label col-form-label">City</label>
                                                    <div className="col-sm-2">
                                                        <input type="text" className="form-control-sm" id="v_city" name="v_city"/>
                                                    </div>
                                                    <label htmlFor="v_state" className="col-sm-1 form__form-group-label col-form-label">State</label>
                                                    <div className="col-sm-1">
                                                        <input type="text" className="form-control-sm" id="v_state" name="v_state"/>
                                                    </div>
                                                    <label htmlFor="v_zip" className="col-sm-1 form__form-group-label col-form-label">Zip</label>
                                                    <div className="col-sm-2">
                                                        <input type="text" className="form-control-sm" id="v_zip" name="v_zip"/>
                                                    </div>
                                                </div>


                                                <div className="form__form-group mt-3">
                                                    <label htmlFor="v_google_place_id" className="form__form-group-label col-form-label">Google Place ID</label>
                                                    <input type="text" className="form-control-sm col-12" id="v_google_place_id" name="v_google_place_id"/>
                                                    <small>To get the google place id of the venue go to: <a href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder" target="_blank">Place Id Finder</a></small>
                                                </div>

                                                <div className="form__form-group row mt-3">
                                                    <label htmlFor="v_lat"
                                                           className="col-sm-2 form__form-group-label col-form-label">Latitude</label>
                                                    <div className="col-sm-4">
                                                        <input type="text" className="form-control-sm" id="v_lat" name="v_lat"/>
                                                    </div>
                                                    <label htmlFor="v_long"
                                                           className="col-sm-2 form__form-group-label col-form-label">Longitude</label>
                                                    <div className="col-sm-4">
                                                        <input type="text" className="form-control-sm" id="v_long" name="v_long"/>
                                                    </div>
                                                </div>

                                                <div className="form__form-group row mt-3">
                                                    <label htmlFor="v_google_rating" className="col-sm-2 form__form-group-label col-form-label">Rating</label>
                                                    <div className="col-sm-4">
                                                        <input type="text" className="form-control-sm" id="v_google_rating" name="v_google_rating"/>
                                                    </div>
                                                </div>


                                                <button className="btn btn-primary" type="button" onClick={updateVenue}>Update</button>


                                            </form>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Col xs="12">
                                                <Row>
                                                    <div className={"col-6"}>
                                                        <InviteMembers/>
                                                    </div>
                                                    <div className={"col-6"}>
                                                        <AddMembers/>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        <hr/>

                                                        <MembersTable/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <VenueGoogleIntegration />
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <StripeContainer component={Subscriptions}/>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Amenities/>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData,
    showVenueMenu:state.venue.showVenueMenu
}))(VenueEdit);