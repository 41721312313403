import { handleActions } from 'redux-actions';
import {
    setVenues
} from '../actions/venuesActions';

const defaultState = {
    venues: []
};

export default handleActions(
  {
    [setVenues](state, { payload }) {
      return { ...state, venues: payload };
    }
  },
  defaultState,
);
