import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelVenue from '../../../../Models/Venue';
import { Alert, Button,Row,Col, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Http from '../../../../containers/App/Http';
import {showNotification} from "../../../../shared/components/Notification";
import Cleave from "cleave.js/react";
import {CardElement,useElements,useStripe} from '@stripe/react-stripe-js';

const CreateSubscription = ({dispatch, users,venueData, rtl,theme,venueMembers}) => {
    const [modal, setModal] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [products,setProducts] = useState(false);
    const [prices,setPrices] = useState(false);
    const [useCustomPricing,setUseCustomPricing] = useState('N');
    const stripe = useStripe();
    const elements= useElements();
    const mu = new ModelVenue();

    useEffect(async () => {
        // load the products
        Http.post("/sb/getProducts").then(async response => {
            if (response.data.status === 'failed') {
                showNotification(theme,'danger',rtl,'Get Products',response.data.msg);
                return false;
            }

            if(!response.data.data.hasOwnProperty('data'))
                return;

            setProducts(response.data.data);
        });

        // Http.post("/sb/getAllPaymentMethods").then(async response => {
        //     if (response.data.status === 'failed') {
        //         showNotification(theme,'danger',rtl,'Get Products',response.data.msg);
        //         return false;
        //     }
        //
        //     debugger
        //     // if(!response.data.data.hasOwnProperty('data'))
        //     //     return;
        //     //
        //     // setProducts(response.data.data);
        // });

        if(typeof venueMembers  ==="undefined")
            setError("The venue has no users");
    },[]);

    useEffect(async () => {

        if(typeof venueMembers  ==="undefined" || venueMembers.length===0){
            setError("The venue has no users");
            return;
        }
        setError(false);
    },[venueMembers]);



    const getPricesByProductId = () =>{
        let p = document.querySelector('#formCreateSubscription [name=productId]');
        let formData = new FormData();
        formData.append('productId',p.value);
        Http.post("/sb/getAllPricesByProductId",formData).then(async response => {
            if (response.data.status === 'failed') {
                showNotification(theme,'danger',rtl,'Get Prices',response.data.msg);
                return false;
            }
            setPrices(response.data.data);
        });

    };

    const toggle = async () => {
        // setError(false);
        setSuccess(false);
        await setModal(prevState => !prevState);
    };

    const handleSubmit= () =>{
        document.querySelector("#formCreateSubscription button[type=submit]").click();
    };

    const continueSubmission=async (form)=>{
        form.preventDefault();
        setProcessing(true);
        let formData = new FormData(document.getElementById('formCreateSubscription'));

        const cardElement = elements.getElement("card");

        const {error,token} = await stripe.createToken(cardElement);
        // const {error, paymentMethod} = await stripe.createToken(cardElement);

        if(error){
            setError(error.message);
            return;
        }

        let subData = new FormData(document.getElementById("formCreateSubscription"));
        subData.append('stripeToken',token.id);
        subData.append('venueId',venueData.v_id);
        if(useCustomPricing === 'Y'){
            const customPriceInStripeFormat = Math.round(document.querySelector('[name=customPrice]').rawValue.replace('$','') * 100);
            subData.append('customPriceInStripeFormat',customPriceInStripeFormat);
        }

        Http.post("/sb/subscribeVenue",subData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);
        });

    };

    return (

            <>
                <Button color={"info"} className={"btn-sm btn-block mb-0 mt-2"} onClick={toggle}>Create Subscription <FontAwesomeIcon icon="user"/></Button>

                <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="lg">
                    <div className="modal__header">
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            aria-label="modal__close-btn"
                            type="button"
                            onClick={toggle}
                        />
                        <h4 className="text-modal  modal__title">Create Subscription</h4>
                    </div>

                    <div className="modal__body">
                        <form id="formCreateSubscription" className={"form--horizontal form"} onSubmit={continueSubmission}>
                            <button type="submit" style={{'display':'none'}}>Submit</button>

                            <Col xs={'12'}>
                                <Alert color="danger" isOpen={!!errorMsg}>
                                    {errorMsg}
                                </Alert>
                                <Alert color="success" isOpen={!!successMsg}>
                                    {successMsg}
                                </Alert>
                            </Col>


                            <div className="form__form-group mt-3">


                                <div className="col-12">
                                    <select className="form-control form-control-sm col-12" id="productId" name="productId" onChange={getPricesByProductId} required={true}>
                                        <option >Select a product</option>
                                        {products !== false && products.data.map(product => <option value={product.id}>{product.name}</option>)}
                                    </select>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="">Use Custom Pricing:</label>
                                    <select className="form-control form-control-sm" id="useCustomPricing" name="useCustomPricing" onChange={e=>{setUseCustomPricing(e.target.value)}}>
                                        <option value="N">No</option>
                                        <option value="Y">Yes</option>
                                    </select>
                                </div>
                                {useCustomPricing==='Y' &&
                                    <div className="form-group col-12">
                                        <Cleave
                                            placeholder="Enter credit card number"
                                            options={{
                                                numeral: true,
                                                prefix: '$'
                                            }}
                                            // onChange={e => onCreditCardChange(e)}
                                            className="form-field form-control"
                                            name="customPrice"
                                        />
                                    </div>
                                }
                                {useCustomPricing === 'N' &&
                                    <div className="form-group col-12" id="priceIdContainer">
                                        <label htmlFor="">Select Price:</label>
                                        <select className="form-control-sm form-control" name="priceId" id="priceId">
                                            {prices!== false &&
                                                prices.data.map(price => <option key={price.id} value={price.id}>{price.unit_amount_display}</option>)
                                            }
                                        </select>
                                    </div>
                                }

                                <div className="form-group col-12" id="priceIdContainer">
                                    <label htmlFor="">Select User:</label>
                                    <select className="form-control-sm form-control" name="userId" id="userId" required={true}>
                                        <option>Select User</option>
                                        {typeof venueMembers  !=="undefined" &&
                                            venueMembers.map(member => <option key={member.u_id} value={member.u_id}>{member.u_email} | {member.r_display_name}</option>)
                                        }
                                    </select>
                                </div>

                                <Col xs={'12'} className={'mt-3 mb-3'}>
                                    <CardElement />
                                </Col>
                                <div className="col-sm-3">
                                    <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit} disabled={errorMsg||processing}>Process</button>
                                </div>
                            </div>

                        </form>

                    </div>

                </Modal>
            </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    users:state.users.users,
    venueData:state.venue.venueData,
    venueMembers:state.venue.members
}))(CreateSubscription);