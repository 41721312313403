import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Panel from "../../../shared/components/Panel";
import DataReactTable from "./DataReactTable";
import { Redirect, useHistory } from "react-router-dom";

const SubmissionsTable = ({ submissionsData }) => {

    const history = useHistory();

    const redirect = (myKey) =>{
        history.push(`/another_page/${myKey}`);
    };

    const columns1 =[
        {
            Header: 'Name',
            accessor: 'name',
            width: 150
        },{
            Header: 'Budget',
            accessor: 'qr_event_budget'
        },{
            Header: 'Number of People',
            accessor: 'qr_num_of_people'
        },{
            Header: 'Event Date',
            accessor: 'qr_event_date'
        },{
            Header: 'Event Type',
            accessor: 'et_name'
        },{
            Header: 'Venue ID',
            accessor: 'v_id'
        },{
            Header: 'Last Name',
            accessor: 'qr_last_name'
        },{
            Header: 'Email',
            accessor: 'qr_email'
        },{
            Header: 'Created Date',
            accessor: 'qr_created_date'
        },{
            Header: 'Allow Other Venue Alts',
            accessor: 'qr_allow_other_venue_options'
        }
        // ,{
        //     width: 300,
        //     Header: "Artist Name",
        //     accessor: "bob",
        //     Cell: ({ cell }) => {
        //         // debugger
        //         return (
        //             <button type={"button"} value={cell.row.values.name} onClick={() => {redirect("bob")}}>
        //                 {cell.row.values.v_name}
        //             </button>
        //         )
        //     }
        // }
    ];

    const tableStructure = {
        tableHeaderData: columns1,
        tableRowsData: submissionsData
    };

    return (
        <Panel
            xl={12}
            lg={12}
            md={12}
            title="Submissions"
            subhead="Submissions for the selected date range"
        >
            <div >

                    <DataReactTable reactTableData={ tableStructure } />

            </div>
        </Panel>
    );
};

export default SubmissionsTable;
