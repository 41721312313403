import React, { useState } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import ModalCreateUser from "./ModalCreateUser";

const CreateUser = ({ venueInfo }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget" >
          <div className="card__title">
            <h5 className="bold-text">Create New User</h5>
          </div>
          <div className="card__body">
              <ModalCreateUser/>
          </div>


        </CardBody>
      </Card>
    </Col>
  );
};

export default CreateUser;
