import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import {connect} from "react-redux";
import CreateUser from "./components/CreateUser";
import SubmissionsTable from "./components/SubmissionsTable";

const Submissions = ({ dispatch }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(async () => {
        // await authCheck().then(() => { });
        setIsLoaded(true);
    },[]);

    return (
        isLoaded && (
            <Container className="dashboard">
                {/*<Row>*/}
                    {/*<CreateUser/>*/}
                {/*</Row>*/}
                <Row>
                    <SubmissionsTable/>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({

}))(Submissions);