/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import AuthService from "../../Services/AuthService";
import { useHistory } from "react-router-dom";

const LogIn = () => {

    const history = useHistory();

    const handleLogin = (loginCredentials) => {
        // this.setState({
        //     message: "",
        //     loading: true
        // });

        AuthService.login(loginCredentials.username, loginCredentials.password).then(
            (response) => {
                if(response===true)
                    history.push("/dashboard");
                else
                    return;
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );

    };


    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">bookWNY <span className="account__logo">ADMIN</span></h3>
                    <h4 className="account__subhead subhead">Start your business easily</h4>
                </div>
                <LogInForm onSubmit={handleLogin}/>
            </div>
        </div>
      </div>
    );

};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
