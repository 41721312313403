import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import Panel from '../../../shared/components/Panel';
import OccupancyTooltipContent from './OccupancyTooltipContent';

const PageViews = ({ themeName, pageViewsData }) => {

  const dataMax = () => {
    let max = 0;
    pageViewsData.forEach(function(x){
        if(parseFloat(x.pageViews) > max){
            max = parseFloat(x.pageViews);
        }
    });
    return Math.ceil(max + (max * .10));
  };

  return (
    <Panel
      xl={8}
      lg={6}
      md={12}
      title="Pages Views"
      subhead="Views per week"
    >
      <div >
        <ResponsiveContainer height={260}>
          <ComposedChart data={pageViewsData} margin={{ top: 20, left: -15 }}>
            {/*<XAxis dataKey="name" tickLine={false} padding={{ left: 20 }}  />*/}
            <XAxis dataKey="date" tickLine={false} padding={{ left: 20 }}  />
            <YAxis tickLine={false} domain={[0, dataMax]} orientation='right' />
            <Tooltip content={<OccupancyTooltipContent colorForKey={{ uv: '#555555' }} theme={themeName} />} />
            <CartesianGrid vertical={false} />
            {/*<Line type="linear" name="Current" dataKey="departure" stroke="#b8e986" />*/}
            <Line type="linear" name="Views" dataKey="pageViews" stroke="#b8e986" />
            {/*<Line type="linear" name="Previous" dataKey="arrival" stroke="#48b5ff" />*/}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Panel>
  );
};

PageViews.propTypes = {
    themeName: PropTypes.string.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(PageViews);
