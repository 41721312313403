import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {setAllSubmissions} from "../redux/actions/submissionsActions";

export default class Submissions{

    getSubmissions(queryPageIndex, queryPageSize){
        // FETCH THE Users
        const formData = new FormData();
        formData.append('queryPageIndex',queryPageIndex);
        formData.append('queryPageSize',queryPageSize);

        return Http.post("/s/getSubmissions",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setAllSubmissions(response.data.data));
            return response;
        });
    };

}