import React from 'react';
import Notification from 'rc-notification';

let notification = null;
// eslint-disable-next-line no-return-assign
Notification.newInstance({ style: { top:60,right: 0 } }, n => notification = n);

const showNotification = (theme,color, rtl, title, message,duration) => {
  const notificationInitialProps = {
    content: <BasicNotification
      color={color ?? "none"}
      title={title}
      message={message}
      theme={theme}
    />,
    closable: true,
    duration: duration ?? 5,
    // style: { top: 0, left: 'calc(100vw - 100%)' },
    style: { top: 0 },
    className: `right-up ${rtl.direction}-support`,
  };
  notification.notice(notificationInitialProps);
};

const BasicNotification = ({
  color, title, message, theme,
}) => (
  <div className={`notification notification--${color} notification--${theme.className}`}>
    <h5 className="notification__title bold-text">{title}</h5>
    <p className="notification__message">{message}</p>
  </div>
);

BasicNotification.defaultProps = {
  color: '',
  title: '',
};

const ImageNotification = ({
  img, title, message, theme,
}) => (
  <div className={`notification notification--image notification--${theme.className}`}>
    <div className="notification__image">
      <img src={img} alt="" />
    </div>
    <h5 className="notification__title bold-text">{title}</h5>
    <p className="notification__message">{message}</p>
  </div>
);


ImageNotification.defaultProps = {
  title: '',
};

const FullWideNotification = ({ color, message }) => (
  <div className={`notification notification--full-wide notification--${color}`}>
    <p className="notification__message">{message}</p>
  </div>
);


FullWideNotification.defaultProps = {
  color: '',
};

export {
  BasicNotification,
  ImageNotification,
  FullWideNotification,
  showNotification,
};
