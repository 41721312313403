import React, {useEffect, useState} from 'react';
import { Col, Container , Row ,Card, CardBody, Progress, Table} from 'reactstrap';
import TotalSubmissions from './components/TotalSubmissions';
import {connect} from "react-redux";
import CreateVenue from "./components/CreateVenue/CreateVenue";
import VenuesTableV2 from './components/VenuesTableV2';
import {showVenueMenu} from "../../redux/actions/venueActions";

const Dashboard = ({ dispatch, venues }) => {

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(async () => {
        dispatch(showVenueMenu(false));

        console.log('useefeect running');
        setIsLoaded(true);
    },[]);

    return (
        isLoaded && (
            <Container className="dashboard">
                <Row>
                    <h1>In Development</h1>
                    {/*<TotalSubmissions/>*/}
                    {/*<CreateVenue />*/}
                    {/*<VenuesTableV2 />*/}
                </Row>
            </Container>
        )
    );
};

export default connect(state => ({
    venues:state.venues.venues
}))(Dashboard);