import React,{ useEffect,useState } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthService from '../../Services/AuthService';
import { useHistory } from "react-router-dom";
import AuthCheck from '../../Services/AuthCheck';

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {

    const history = useHistory();
    const [isLoaded, setLoaded] = useState(false);

    let isAuthenticated = localStorage.getItem("isAuthenticated");

    useEffect(()=>{

        history.listen(async (location)=>{
            await AuthCheck();
        })
    },[]);

    const firstLoad = async () => {
        if(!isLoaded){
        setLoaded(true);
        await AuthCheck();
    }};
    firstLoad();

    // if(localStorage.getItem('expire_at') !== null){
    //     let eat = new Date(localStorage.getItem('expire_at') * 1000);
    //     let now = new Date();
    //
    //     if(now.getTime() > eat.getTime()){
    //         // NOW IS NEWER THAN THE EAT. TOKEN EXPIRED
    //
    //         // ATTEMPT TO USE THE REFRESH TOKEN
    //
    //
    //         // UPDATE THE LOCAL STORAGE TO isAuthenticated = "N"
    //         AuthService.logout().then(response => {
    //             isAuthenticated = "N";
    //             return history.push("/login");
    //
    //         });
    //     }
    // }

    console.log("this", isAuthenticated);

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                (isAuthenticated === "Y") ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    );
};

export default ProtectedRoute;