import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelUsers from '../../../../Models/Users';
import { Alert, Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';

const ModalCreateUser = ({dispatch, venueData,users, rtl}) => {
    const [modal, setModal] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const mu = new ModelUsers();

    useEffect(async () => {
        // setTier(venueData.vti_advanced_id);
    },[venueData,users]);

    const toggle = async () => {
        setError(false);
        setSuccess(false);
        await setModal(prevState => !prevState);
    };

    const handleSubmit= (form) =>{
        form.preventDefault();
        let formData = new FormData(document.getElementById('createUserForm'));

        mu.createNewUser(formData).then(response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setSuccess(false);
                return false;
            }

            setError(false);
            setSuccess(response.data.msg);
            mu.getUsers().then(response=>{
                if (!response) {
                    alert('failed to load users');
                    return false;
                }
                return true;
            });
        });
    };

    return (
        <div>
            <Button color={"info"} className={"btn-sm btn-block mb-0 mt-2"} onClick={toggle}>Create</Button>

            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="lg">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Create New User</h4>
                </div>
                <form id="createUserForm" className={"form--horizontal form"} onSubmit={handleSubmit}>

                <div className="modal__body">
                    <Alert
                        color="danger"
                        isOpen={!!errorMsg}
                    >
                        {errorMsg}
                    </Alert>
                    <Alert
                        color="success"
                        isOpen={!!successMsg}
                    >
                        {successMsg}
                    </Alert>
                        <div className={'row'}>
                            <div className="form__form-group mt-3">
                                <label htmlFor="u_first" className="col-sm-2 form__form-group-label col-form-label">First Name</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm" id="u_first" name="u_first" />
                                </div>
                                <label htmlFor="u_last" className="col-sm-2 form__form-group-label col-form-label">Last Name</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm" id="u_last" name="u_last" />
                                </div>
                            </div>
                            <div className="form__form-group mt-3">
                                <label htmlFor="u_email" className="col-sm-2 form__form-group-label col-form-label">Email</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm" id="u_email" name="u_email" />
                                </div>
                                <label htmlFor="u_main_phone" className="col-sm-2 form__form-group-label col-form-label">Phone</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm" id="u_main_phone" name="u_main_phone" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className={'col-12 text-right'}>
                                <button className="btn btn-primary btn-sm" type="submit">Create</button>
                                <button className="btn btn-danger btn-sm" type="button" onClick={toggle}>Close</button>
                            </div>
                        </div>



                </div>
                </form>

            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    dateRangeEnding: state.venue.dateRangeEnding,
    dateRangeStarting:state.venue.dateRangeStarting,
    users:state.users.users,
}))(ModalCreateUser);