import Http from "../containers/App/Http";


class AuthService {
    login(username, password) {
        const formData = new FormData();
        formData.append('username',username);
        formData.append('password',password);

        return Http
            .post("auth/login", formData,{
                headers:{
                    'content-type':'multipart/formData'
                }
            })
            .then(response => {
                if (response.data.status === 'failed') {
                    alert(response.data.msg);
                    return false;
                }

                let jwt = response.data.data.jwt;
                let user = response.data.data.user;
                let refreshToken = response.data.data.refreshToken;
                let expire_at = response.data.data.expireAt;

                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("access_token", jwt);
                localStorage.setItem("refresh_token", refreshToken);
                localStorage.setItem("expire_at", expire_at);
                localStorage.setItem("isAuthenticated", "Y");

                // SET Http TO USE THE TOKEN
                Http.defaults.headers.common = {
                    'Authorization': `Bearer ${localStorage.access_token}`
                };
                //GO TO DASHBOARD OR REDIRECT LINK

                return true;
            });
    }

    refreshToken(){
        // CHECK FOR A REFRESH TOKEN IN THE LOCAL STORAGE
        const formData = new FormData();
        formData.append('refresh_token', localStorage.getItem("refresh_token"));
        return Http
            .post("auth/refreshToken", formData,{
                headers:{
                    'content-type':'multipart/formData'
                }
            })
            .then(response => {
                if (response.data.status === 'failed') {
                    return false;
                }

                // let jwt = response.data.data.jwt;
                // let refreshToken = response.data.data.refreshToken;
                // let expire_at = response.data.data.expireAt;
                //
                // localStorage.setItem("access_token", jwt);
                // localStorage.setItem("refresh_token", refreshToken);
                // localStorage.setItem("expire_at", expire_at);
                // localStorage.setItem("isAuthenticated", "Y");
                //
                // // TODO: ADD THE USER OBJ TO REDUX
                //
                //
                // // SET Http TO USE THE TOKEN
                // Http.defaults.headers.common = {
                //     'Authorization': `Bearer ${localStorage.access_token}`
                // };
                // //GO TO DASHBOARD OR REDIRECT LINK

                return true;
            });
    }

    logout() {

        //localStorage.removeItem("user");
        return Http
            .post("auth/logout")
            .then(response => {
                if (response.data.status === 'failed') {
                    alert('failed to logout');
                    return false;
                }
                localStorage.setItem("isAuthenticated", "N");

                return true
            });


    }

}

export default new AuthService();