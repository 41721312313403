import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Panel from "../../../shared/components/Panel";
import {CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import OccupancyTooltipContent from "./OccupancyTooltipContent";

// ACTION CLICK ARE A REFERENCE TO SOMEONE CLICKING ON "SHOW NUMBER" OR "GO TO WEBSITE"
const ActionClicks = ({ eventsData }) => {

    const {pageViews, showVenuePhone, goToVenueWebsite, getVenueDirections, numberOfSubmissions} = eventsData;

    return (
        <Panel
            xl={4}
            lg={6}
            md={12}
            title="Events"
            subhead="Events Summary"
        >
            <div >
                <div className={"row"}>
                    <div className={"col-8"}>
                        <h4><span className="lnr lnr-arrow-right-circle" style={{color:'#FF0000'}}/> Page Views: </h4>

                    </div>
                    <div className={"col-4"}>
                        <h4>{ pageViews }</h4>
                    </div>
                    <div className={"col-12"}><p style={{margin:0,padding:0}}>Total number of page views</p></div>
                </div>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <h4><span className="lnr lnr-arrow-right-circle" style={{color:'#FF0000'}}/> Show Venue Phone: </h4>
                    </div>
                    <div className={"col-4"}>
                        <h4>{ showVenuePhone }</h4>
                    </div>
                    <div className={"col-12"}><p style={{margin:0,padding:0}}>Users that clicked on 'Show Phone' button</p></div>
                </div>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <h4><span className="lnr lnr-arrow-right-circle" style={{color:'#FF0000'}}/> Go To Venue Website: </h4>
                    </div>
                    <div className={"col-4"}>
                        <h4>{ goToVenueWebsite }</h4>
                    </div>
                    <div className={"col-12"}><p style={{margin:0,padding:0}}>Users that clicked to go to the venue website</p></div>
                </div>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <h4><span className="lnr lnr-arrow-right-circle" style={{color:'#FF0000'}}/> Get Venue Directions: </h4>
                    </div>
                    <div className={"col-4"}>
                        <h4>{ getVenueDirections }</h4>
                    </div>
                    <div className={"col-12"}><p style={{margin:0,padding:0}}>Users that clicked on get directions</p></div>
                </div>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <h4><span className="lnr lnr-arrow-right-circle" style={{color:'#FF0000'}}/> Number of Submissions:</h4>
                    </div>
                    <div className={"col-4"}>
                        <h4>{ numberOfSubmissions }</h4>
                    </div>
                    <div className={"col-12"}><p style={{margin:0,padding:0}}>Users that completed submissions</p></div>
                </div>
            </div>
        </Panel>
    );
};

export default ActionClicks;
