import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import {connect} from "react-redux";
import UsersTable from "./components/UsersTableV2";
import CreateUser from "./components/CreateUser";

const Users = ({ dispatch,venueData }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(async () => {
        // await authCheck().then(() => { });
        setIsLoaded(true);
    },[]);

    return (
        isLoaded && (
            <Container className="dashboard">
                <Row>
                    <CreateUser/>
                </Row>
                <Row>
                    <UsersTable/>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    venueData:state.venue.venueData
}))(Users);