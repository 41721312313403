/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import AuthService from "../../Services/AuthService";
import { Redirect } from 'react-router-dom';


export default class LogOut extends React.Component{

    constructor(props) {
        super(props);

    }

    render(){
        if(!AuthService.logout())
            return;

        return(
            <Redirect to='/login' />
        )
    }
}

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
